import React,{useEffect, useState} from 'react';
import './pricing.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';

import Config from '../../../config/config';
import FAQ from '../faq/faq';
import Button from '../../elements/button/button';
import Modal from '../../common/modal/modal';
import BillingRate from './billing-rate';
import LogoPng from '../../../css/imgs/logo-white.png';
import Stripe from '../../common/payment/stripe';
import Paypal from '../../common/payment/paypal';
import Dawipay from '../../common/payment/dawipay';
import AccountStatusEnum from '../../../enums/account-status';
import PlanEnum from '../../../enums/plan';
import PlatformEnum from '../../../enums/platform';
import useUserState from '../../../store/user';
import {clone} from '../../../utils/clone';
import { Link, Redirect } from 'react-router-dom';
import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import Loading from '../../common/loading/loading';


import {ReactComponent as LoginSvg} from '../../../css/imgs/icon-login.svg';
import {ReactComponent as CreateAccountSvg} from '../../../css/imgs/icon-person-add.svg';



let googleLink = "";
let billingRates = [
  {month:1,save:0},
  {month:12,save:20},
];
let pricingTiers = [
  {
    id: PlanEnum.Basic,
    name:"Basic",
    price: 0,
    banner: "Free Forever",
    features:[
      <><span className="bold">20</span> Daily Midjourney Generations</>,
      <><span className="bold">20</span> Monthly ChatGPT Requests</>,
      <>API Access</>,
      <>Prompt Folders</>,
      <>Prompt Generator</>,
      <>Prompt Variations</>,
    ],
    free:true,
    actionButton: googleLink,
  },
  {
    id:"premium",
    name:"Premium",
    price: 289,
    banner: "Most Popular",
    features:[
      <>All Starter Features</>,
      <><span className="bold">Unlimited</span> Daily Midjourney Generations</>,
      <><span className="bold">500</span> Monthly ChatGPT Requests</>,
    ]
  },
  // {
  //   id: PlanEnum.Pro,
  //   name:"Pro",
  //   price: 579,
  //   banner: "Best Value",
  //   features:[
  //     <>All Starter Features</>,
  //     <><span className="bold">Unlimited</span> Daily Midjourney Generations</>,
  //     <><span className="bold">2,000</span> Monthly ChatGPT Requests</>,
  //   ]
  // },
]

const Pricing = () => {

  const {user,setUser} = useUserState();

  const [loading,setLoading] = useState(false);

  const [billingRate,setBillingRate] = useState(billingRates[0]);
  const [pricingTier,setPricingTier] = useState(pricingTiers[0]);
  const [paymentOption,setPaymentOption] = useState(PlatformEnum.Stripe);
  const [stripePriceUid,setStripePriceUid] = useState();
  const [paypalPriceUid,setPaypalPriceUid] = useState();
  const [dawipayPriceUid,setDawipayriceUid] = useState();
  const [newAccountStatus,setNewAccountStat] = useState();
  const [show,setShow] = useState(false);
  const [redirect,setRedirect] = useState(false);

  const [updateStatus,setUpdateStatus] = useState(false);
  const [newStatus,setNewStatus] = useState();

  const [accountResults,setAccountResults] = useState({});
  const [hasActiveSub,setHasActiveSub] = useState(false);
  const [initMountSub,setInitMountSub] = useState(false);

  useEffect(() => {
    refreshSubStatus();
    return () => {
      unSubRequest("subscription-status");
    }
  },[]);


  useEffect(() => {
    if(updateStatus && newStatus !== undefined){
      let cloneUser = clone(user);
      cloneUser.status = newStatus;    
      setUser(cloneUser);
      setUpdateStatus(false);
    }
  },[user,setUser,updateStatus,newStatus])

  useEffect(() => {
    let newStripeUid = Config.PriceUids.Stripe.Premium.Month1;
    let newPaypalUid = Config.PriceUids.Paypal.Premium.Month1;
    let newDawipayUid = Config.PriceUids.Dawipay.Premium.Month1;
    let newerAccountStatus = AccountStatusEnum.Premium;
    if(billingRate.month === 1){
      if(pricingTier.id === "premium"){
        // Default
      }else if(pricingTier.id === "pro"){
        newStripeUid = Config.PriceUids.Stripe.Pro.Month1;
        newPaypalUid = Config.PriceUids.Paypal.Pro.Month1;
        newDawipayUid = Config.PriceUids.Dawipay.Pro.Month1;
        newerAccountStatus = AccountStatusEnum.Pro;
      }
    }else if(billingRate.month === 12){
      if(pricingTier.id === "premium"){
        newStripeUid = Config.PriceUids.Stripe.Premium.Year1;
        newPaypalUid = Config.PriceUids.Paypal.Premium.Year1;
        newDawipayUid = Config.PriceUids.Dawipay.Premium.Year1;
        newerAccountStatus = AccountStatusEnum.Premium;
      }else if(pricingTier.id === "pro"){
        newStripeUid = Config.PriceUids.Stripe.Pro.Year1;
        newPaypalUid = Config.PriceUids.Paypal.Pro.Year1;
        newDawipayUid = Config.PriceUids.Dawipay.Pro.Year1;
        newerAccountStatus = AccountStatusEnum.Pro;
      }
    }
    setStripePriceUid(newStripeUid);
    setPaypalPriceUid(newPaypalUid);
    setDawipayriceUid(newDawipayUid);
    setNewAccountStat(newerAccountStatus);
  },[billingRate,pricingTier]);


  const formatUSD = (amount) => {
    amount = amount/100;
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(amount);
  }

  let monthlyCost = pricingTier.price;
  let totalCost = monthlyCost * billingRate.month;
  if(billingRate.save){
    monthlyCost = monthlyCost * (1 - (billingRate.save/100))
    totalCost = totalCost * (1 - (billingRate.save/100))
  } 

  let monthlyCostStr = formatUSD(monthlyCost);
  let totalCostStr = formatUSD(totalCost);

  const successCallback = () => {
    let cloneUser = clone(user);
    cloneUser.status = newAccountStatus;
    setUser(cloneUser);
    setRedirect("/subscribed/"+newAccountStatus);
  }

  const refreshSubStatus = () => {
    if(!user){ return;}
    setLoading(true);
    request("subscription-status","/subscription-status?force","GET", {}, {
      then: function(res){
        let results = {};
        let newStatus = -1;
        let newUpdateStatus = false;
        let newSubAutoRenew = false;
        if(res.data.res){
          results = res.data.res;
          if(results.autoRenew){
            newSubAutoRenew = true;
          }
        }
        if(results.user && results.user.status){
          newStatus = results.user.status;
          newUpdateStatus = true;
        }
        setAccountResults(results);
        setHasActiveSub(results.hasActiveSub?true:false);
        setUpdateStatus(newUpdateStatus);
        setNewStatus(newStatus);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  };


  useEffect(() => {
    let newInitMountSub = initMountSub;
    let newPricingTier = pricingTier;
    if(hasActiveSub && initMountSub === false){
      newInitMountSub = true;
      // newPricingTier
      let currentPlan = PlanEnum.Basic;
      if(hasActiveSub && accountResults.plan){
        for(let key in PlanEnum){
          if(PlanEnum[key] === accountResults.plan){ currentPlan = PlanEnum[key]; break; }
        }
      }
      for (let i = 0; i < pricingTiers.length; i++) {
        const item = pricingTiers[i];
        if(item.id === currentPlan){
          newPricingTier = item;
          break;
        }
      }
    }
    setPricingTier(newPricingTier);
    setInitMountSub(newInitMountSub);
  },[hasActiveSub,initMountSub,pricingTier]);

  let currentPlan = PlanEnum.Basic;
  if(hasActiveSub && accountResults.plan){
    for(let key in PlanEnum){
      if(PlanEnum[key] === accountResults.plan){ currentPlan = PlanEnum[key]; break; }
    }
  }

  if(redirect){
    return(
      <Redirect to={redirect}/>
    )
  }

  return (
    <div className="pricing-comp">

      <Helmet>
        <title>{Seo.Pricing.Title}</title>
        <meta name="description" content={Seo.Pricing.Desc} />
        <meta name="keywords" content={Seo.Pricing.Keywords} />
      </Helmet>

      <h1>Midjourney API Pricing</h1>
      
      <BillingRate billingRate={billingRate} setBillingRate={setBillingRate} billingRates={billingRates}/>

      <div className='pricing-options hlo'>
        {pricingTiers.map((v,i) => {
          let activeClass = v.id === pricingTier.id?"active":"";
          let currentPlanClass = v.id === currentPlan?"current-plan":""

          let price = v.price;
          if(billingRate.save !== 0){
            price = price * (1 - (billingRate.save/100))
          }
          let dollar = Math.floor(price / 100);
          let cent = Math.round(price % 100);
          let centString = cent.toString().padStart(2, '0');

          return(
            <div className={"pricing-option "+activeClass+" "+currentPlanClass} key={i} onClick={() => {
              if(loading){
                toast.info("Loading Sub info, one moment please.");
                return;
              }
              if(v.id === pricingTier.id && !v.free){
                setShow(true);
              } 
              setPricingTier(v);
            }}>
              {v.banner?
                <div className='banner'><span>{v.banner}</span></div>
              :null}
              <div className='title'>{v.name}</div>
              <div className='features'>
                {v.features.map((v2,i2) => {
                  return(
                    <div className='feature' key={i2}>{v2}</div>
                  )
                })}
              </div>

              <div className='price'>
                <div className='price-container'>
                  <div className='symbol'>$</div>
                  <div className='dollar'>{dollar}</div>
                  <div className='cents'>{centString}</div>
                </div>
                <div className='per-month'>per month</div>
              </div>
              <div className='buttons'>
                <Button className="" status={loading?"loading":"forward"} onClick={() => {
                  setPricingTier(v);
                  setShow(v.free?false:true);
                }}>{v.free?"Get Started":"Upgrade"}</Button>
              </div>
              {(v.id === currentPlan)?
                <div className='current-plan-div'>
                  <div className='current-plan-div-inner'></div>
                  <div className='text'>
                    {loading?
                    <Loading show={loading} size={24} style={"white"} />
                    :"Current Plan"}
                  </div>
                  
                </div>
              :null}
            </div>
          )
        })}
      </div>

      <div className="faq">
        <FAQ />
      </div>

      <Modal show={show} handleClose={() => {setShow(false)}} className="checkout-modal">
        <div className='checkout-modal-inner'>
          <div className='checkout-modal-header'>
            <h2>Your Plan</h2>
            <div className='checkout-modal-billing-rate-container'>
              <BillingRate billingRate={billingRate} setBillingRate={setBillingRate} billingRates={billingRates}/>
            </div>
          </div>

          <div className='items'>
            <div className='item'>
              <div className='left'>
                <div className='icon'><img src={LogoPng}/></div>
                <div className='item-text'>
                  <div className='product-name'>{Config.Common.AppName} {pricingTier.name}</div>
                  <div className='billing-rate-text'>{billingRate.month === 1?"Billed Monthly":"Billed Every "+billingRate.month+" Months"}</div>
                  <div className='easy-cancel'>Easily cancel at anytime</div>
                </div>
              </div>
              <div className='right'>
                {monthlyCostStr}/month
              </div>
            </div>
            <div className='total'>
              <div className='left'>Total</div>
              <div className='right'>{totalCostStr} USD</div>
            </div>
          </div>

          

          {(user)?
          <>
            <div>
              <h4>Payment Method</h4>
            </div>
            <div className='payment-options hlo'>
              <div className={"payment-option "+((paymentOption === PlatformEnum.Stripe?"active":""))} onClick={() => {setPaymentOption(PlatformEnum.Stripe)}}>Credit Card</div>
              <div className={"payment-option "+((paymentOption === PlatformEnum.Paypal?"active":""))} onClick={() => {setPaymentOption(PlatformEnum.Paypal)}}>PayPal</div>
              {/* <div className={"payment-option "+((paymentOption === PlatformEnum.Dawipay?"active":""))} onClick={() => {setPaymentOption(PlatformEnum.Dawipay)}}>Crypto</div> */}
            </div>
            <div className='payment-div'>
              <div className={(paymentOption === PlatformEnum.Stripe)?"":"hide"}>
                <Stripe newAccountStatus={newAccountStatus} priceUid={stripePriceUid} callback={() => {
                  successCallback();
                }}/>
              </div>
              <div className={(paymentOption === PlatformEnum.Paypal)?"":"hide"}>
                <Paypal newAccountStatus={newAccountStatus} priceUid={paypalPriceUid} callback={() => {
                  successCallback();
                }}/>
              </div>
              <div className={(paymentOption === PlatformEnum.Dawipay)?"":"hide"}>
                <Dawipay newAccountStatus={newAccountStatus} priceUid={dawipayPriceUid} callback={() => {
                  successCallback();
                }}/>
              </div>
            </div>
          </>:
          <>
            <div className='sign-in-div'>
              <div className='header-text'>Create an account or Sign in to continue</div>
              <div className='buttons'>
                <Link to="create-account" className='button'>
                  <div className='icon'><CreateAccountSvg/></div>
                  <div className='text'>Create Account</div>
                </Link>
                <Link to="login" className='button'>
                  <div className='icon'><LoginSvg/></div>
                  <div className='text'>Login</div>
                </Link>
              </div>

            </div>
          </>}
          

        </div>
      </Modal>
    </div>
  );

};

export default Pricing;